// import packages
import React, {Component} from "react";
import Slider from "react-slick";

//Import styles
import "../../assets/styles/homepage/services.scss";

//Import Components
import {SliderNextButton, SliderPrevButton} from "../uiElements/buttons";
import {Link} from "react-router-dom";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {openUrl} from "../../utils/helperFunctions";

// Import utils

class Services extends Component {
    constructor() {
        super();
        this.state = {
            disabledPrev: true,
            disabledNext: false,
        };
    }

    componentDidMount() {
        let itemCount = this.getShowSlideCount();
        this.setState({
            ...this.state,
            disabledNext: this.props?.services?.length - 1 < itemCount,
        });
    }

    getShowSlideCount = () => {
        let width = window.innerWidth;
        if (width > 1050) return 4;
        else if (width > 600) return 2;
        else return 1;
    };

    afterSlideChange = (next) => {
        let itemCount = this.getShowSlideCount();
        this.setState({
            disabledPrev: next === 0,
            disabledNext: next + itemCount === this.props.services?.length,
        });
    };

    render() {
        const {services, staticTexts} = this.props;
        const {disabledPrev, disabledNext} = this.state;
        const settings = {
            dots: false,
            arrows: false,
            infinite: window.innerWidth < 1050,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            centerMode: window.innerWidth < 1050,
            centerPadding: ' 60px 0 0',
            swipe: true,
            ref: section => (this.section = section),
            beforeChange: (current, next) => this.afterSlideChange(next),
            responsive: [
                {
                    breakpoint: 1600,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 1050,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        };

        return !!services?.length && <div className="services-wrapper">
            <div className="services-title">{staticTexts?.homepage_services_title}</div>
            <div className="services-list-wrapper">
                {
                    <Slider {...settings}>
                        {services?.map(item => {
                            return <div key={item?.id}
                                        onClick={() => openUrl(item?.pageSlug, item?.url)}
                                        style={{
                                            cursor: (item?.pageSlug || item?.url) && 'pointer'
                                        }}
                                        className="service-card">
                                <div className="image-wrapper">
                                    <div className="image-proportion">
                                        <img src={generateImageMediaUrl(item?.mediaMain?.path)}
                                             title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                             alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>
                                    </div>
                                <div className="title-wrapper">
                                    <div className="service-title">{item?.title}</div>
                                </div>
                                </div>
                            </div>
                        })}
                    </Slider>
                }
                {!!services?.length && <>
                    <SliderPrevButton disabledPrev={window.innerWidth > 1050 && disabledPrev}  cbPrev={() => this.section.slickPrev()}/>
                    <SliderNextButton disabledNext={disabledNext} cbNext={() => this.section.slickNext()}/>
                </>}
            </div>
        </div>
    }
}

export default Services;
