// import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//Import assets
import '../assets/styles/containers/custom-pages.scss';

//Import Utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {GetCustomPages, ClearCustomPage} from "../redux/actions";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";
import MetaTags from "../components/MetaTags";

class CustomPage extends Component {
    componentDidMount() {
        const url = this.props?.match?.params?.slug;
        this.props.GetCustomPages(url);
    }

    componentWillUnmount() {
        this.props.ClearCustomPage()
    }

    render() {
        const {customPages} = this.props;

        return <div className="custom-page-wrapper">
            <MetaTags title={customPages?.title}
                      image={generateImageMediaUrl(generateImageMediaUrl(customPages?.mediaMain?.path))}/>
            {customPages?.title && <div className="custom-page-title">{customPages?.title}</div>}
            <div className="custom-page-content">
                {customPages?.mediaMain && <div className='left-part'>
                    <img src={generateImageMediaUrl(customPages?.mediaMain?.path)}
                         title={customPages?.mediaMain?.title ? customPages?.mediaMain?.title : ''}
                         alt={customPages?.mediaMain?.altAttribute ? customPages?.mediaMain?.altAttribute : ''}
                         className="custom-page-img"/>
                </div>}
                {customPages?.content &&
                <div className='right-part sun-editor-editable' dangerouslySetInnerHTML={{__html: customPages?.content}}/>}
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'customPages',
    ])
};

const mapDispatchToProps = {GetCustomPages, ClearCustomPage};

export default connect(mapStateToProps, mapDispatchToProps)(CustomPage);