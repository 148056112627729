// import packages
import React, {Component} from "react";
import Slider from "react-slick";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

//Import styles
import "../../assets/styles/homepage/description.scss";

//Import Components
import {SliderButton} from "../uiElements/buttons";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {openUrl} from "../../utils/helperFunctions";

class Description extends Component {
    constructor() {
        super();
        this.state = {
            disabledPrev: true,
            disabledNext: false,
        };
    }

    componentDidMount() {
        let itemCount = this.getShowSlideCount();
        this.setState({
            disabledNext: this.props.features?.length - 1 < itemCount,
        });

    }

    afterSlideChange = (next) => {
        let itemCount = this.getShowSlideCount();
        this.setState({
            disabledPrev: next === 0,
            disabledNext: next + itemCount === this.props.features?.length,
        });
    };

    getShowSlideCount = () => {
        let width = window.innerWidth;
        if (width > 1200) return 5;
        else if (width > 900) return 4;
        else if (width > 600) return 3;
        else return 2;
    };

    render() {
        const {staticTexts, features} = this.props;
        const {disabledPrev, disabledNext} = this.state;

        const settings = {
            dots: false,
            arrows: false,
            infinite: window.innerWidth < 700,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: window.innerWidth < 700,
            centerPadding: '20px 0 0',
            ref: description => (this.description = description),
            beforeChange: (current, next) => this.afterSlideChange(next),
            responsive: [
                // {
                //     breakpoint: 1200,
                //     settings: {
                //         slidesToShow: 4,
                //     }
                // },
                // {
                //     breakpoint: 900,
                //     settings: {
                //         slidesToShow: 3,
                //     }
                // },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 450,
                    settings: {
                        slidesToShow: 1,
                        centerPadding: '60px 0 0',
                    }
                },
            ]
        };

        return !!features?.length && <div className="description-wrapper">

            <div className="description-content">
                <div className="description-proportion">
                    <p>{staticTexts?.homepage_features_description}</p>
                    <div className="title">{staticTexts?.homepage_features_title}</div>
                    <div className="text">{staticTexts?.homepage_features_text}</div>
                    <Link to={'/team'} className={'about-us'}>{staticTexts?.btn_team}</Link>
                </div>
            </div>

            <div className="description-list-wrapper">
                {
                    <Slider {...settings}>
                        {features?.map((item, index) => {
                            return <div className="description-item" key={item.id} onClick={() => openUrl(item?.pageSlug, '')}>
                                <div className="description-item-content">
                                    <div className="index">{index < 10 ? `0${index + 1}` : index + 1}</div>
                                    <div className="item-title">{item?.title}</div>
                                    <span className="line"/>
                                    <div className="item-description">{item?.description}</div>
                                </div>
                            </div>
                        })}
                    </Slider>
                }
                {!!features?.length &&
                <SliderButton
                    className={'mobile-arrows'}
                    cbPrev={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.description.slickPrev()
                    }}
                    cbNext={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.description.slickNext()
                    }}
                />}
            </div>

            {!!features?.length &&
            <SliderButton
                className={'desktop-arrows'}
                cbPrev={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.description.slickPrev()
                }}
                cbNext={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.description.slickNext()
                }}
            />}
        </div>
    }
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
    ])
};

export default connect(mapStateToProps)(Description);
