// import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

//Import assets
import "../assets/styles/containers/blog-details.scss";

//Import components
import MetaTags from "../components/MetaTags";

//Import utils
import { GetSingleBlog } from "../redux/actions";
import { getPropsFromState } from "../redux/mapStateToProps";
import { generateImageMediaUrl } from "../utils/generateMediaUrl";

class BlogDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blog: props.location?.state?.data || null,
    };
  }

  componentDidMount() {
    if (!this.blog) {
      this.props.GetSingleBlog(this.props.match.params?.slug).then((res) => {
        const data = res && res.data;

        this.setState({
          blog: data,
        });
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedLanguage } = this.props;

    if (
      selectedLanguage &&
      prevProps.selectedLanguage &&
      selectedLanguage.code !== prevProps.selectedLanguage.code
    ) {
      this.props.GetSingleBlog(this.props.match.params?.slug).then((res) => {
        const data = res && res.data;

        this.setState({
          blog: data,
        });
      });
    }
  }

  render() {
    const { blog } = this.state;
    return (
      <div className="blog-details-wrapper">
        <MetaTags
          title={blog?.metaTitle}
          description={blog?.metaDescription}
          image={generateImageMediaUrl(
            generateImageMediaUrl(blog?.mediaMain?.path)
          )}
        />
        <div className="image-wrapper">
          <div className="image-proportion">
            <img
              src={generateImageMediaUrl(blog?.mediaMain?.path)}
              alt={
                blog?.mediaMain?.altAttribute
                  ? blog?.mediaMain?.altAttribute
                  : ""
              }
              title={blog?.mediaMain?.title ? blog?.mediaMain?.title : ""}
            />
          </div>
        </div>
        <div className="date-box">
          <div className="category">{blog?.category?.title}</div>
          <div className="date">
            {moment(blog?.updatedAt).format("DD.MM.YYYY")}
          </div>
        </div>
        <h1 className="details-title">{blog?.title}</h1>
        <div
          className="sun-editor-editable details-description"
          dangerouslySetInnerHTML={{ __html: blog?.description }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return getPropsFromState(state, ["selectedLanguage"]);
};

const mapDispatchToProps = { GetSingleBlog };

export default connect(mapStateToProps, mapDispatchToProps)(BlogDetails);
