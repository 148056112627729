export const GENERAL_CONSTS = {
    GET_STATIC_TEXTS: "GET_STATIC_TEXTS",
    GET_LANGUAGES: "GET_LANGUAGES",
    CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
    GET_CONTACTS: "GET_CONTACTS",
    GET_ABOUT: "GET_ABOUT",
    GET_SLIDER: "GET_SLIDER",
    GET_MENU: "GET_MENU",
    SEND_MESSAGE: "SEND_MESSAGE",
    SEND_SUBSCRIBERS: "SEND_SUBSCRIBERS",
    CALL_REQUEST: "CALL_REQUEST",
    GET_CUSTOM_PAGES: "GET_CUSTOM_PAGES",
    CLEAR_CUSTOM_PAGE: "CLEAR_CUSTOM_PAGE",
    GET_SERVICES: "GET_SERVICES",
    GET_WORKS: "GET_WORKS",
    GET_MEMBERS: "GET_MEMBERS",
    GET_PARTNERS: "GET_PARTNERS",
    GET_FEATURES: "GET_FEATURES",
    GET_CATEGORIES: "GET_CATEGORIES",
    GET_BLOGS: "GET_BLOGS",
    GET_BLOG_PAGE: "GET_BLOG_PAGE",
    GET_SINGLE_BLOG: "GET_SINGLE_BLOG",
    GET_COUNTRY_CODE: "GET_COUNTRY_CODE",
};
