// import packages
import React, {useState} from "react";
import {connect} from "react-redux";
import GoogleMapReact from 'google-map-react';

//import styles
import "../assets/styles/containers/locations.scss";
import {ReactComponent as Location} from '../assets/images/icons/ic_map.svg';

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {GOOGLE_MAP_KEY} from "../configs/variables";

const MAP_DEFAULT_CENTER = {
    lat: 40.177583,
    lng: 44.512516
};
const MAP_DEFAULT_ZOOM = 13;


function Locations(props) {
    const [location, setLocation] = useState({
        latitude: 40.177583,
        longitude: 44.512516
    });

    let defaultAddress = props.contacts?.location?.latitude && props.contacts?.location?.longitude ? {
        lat: props.contacts?.location?.latitude,
        lng: props.contacts?.location?.longitude
    } : MAP_DEFAULT_CENTER;

    return <div className={`locations-wrapper`}>
        <div className={'map'}>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: GOOGLE_MAP_KEY,
                    libraries: 'places'
                }}
                defaultCenter={defaultAddress}
                center={defaultAddress}
                distanceToMouse={() => {
                }}
                defaultZoom={MAP_DEFAULT_ZOOM}
                yesIWantToUseGoogleMapApiInternals={true}
            >
                {
                    !!location && <Marker lat={props.contacts?.location?.latitude}
                                          lng={props.contacts?.location?.longitude}/>
                }
            </GoogleMapReact>
        </div>
    </div>
}

const Marker = (props) => <div className={`marker ${props.className}`}>
    <Location title={''}/>
</div>;

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'contacts',
    ])
};

const mapDispatchToProps = {};


export default connect(mapStateToProps, mapDispatchToProps)(Locations);
