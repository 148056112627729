// import packages
import React, { Component } from "react";
import { connect } from "react-redux";

//Import assets
import "../assets/styles/containers/team.scss";
import { ReactComponent as InIcon } from "../assets/images/icons/ic_in.svg";
import { ReactComponent as MailIcon } from "../assets/images/icons/ic_mail.svg";
import { ReactComponent as PhoneIcon } from "../assets/images/icons/ic_phone.svg";
import { ReactComponent as TelegramIcon } from "../assets/images/icons/ic_telegram.svg";
import { ReactComponent as WhatsAppIcon } from "../assets/images/icons/ic_whatsapp.svg";

//Import components
import { PageStart } from "../components/uiElements/PageStart";

//Import utils
import { getPropsFromState } from "../redux/mapStateToProps";
import { GetMembers } from "../redux/actions";
import { generateImageMediaUrl } from "../utils/generateMediaUrl";
import { Link } from "react-router-dom";
import { Typography } from "antd";

class Team extends Component {
  constructor() {
    super();
    this.state = {}
  }

  componentDidMount() {
    const { members } = this.props;
    if (members?.members?.length) {

      const orderedItems = members?.members.sort((a, b) => {
        if (a?.section?.id === "65cc82f80b2c5cf7740f286a") {
          return -1
        }

        if (a?.section?.id === "65cc82f80b2c5cf7740f286c") {
          return 1
        }

        return 0
      }).sort((a, b) => {
        if (a?.section?.id === "65cc82f80b2c5cf7740f286b") {
          return -1
        }
        return 0
      })


      const items = orderedItems.reduce((acc, curr) => {
        if (curr?.section) {
          acc[curr?.section.title] = [...(acc?.[curr?.section.title] || []), curr]
        }
        return acc
      }, {})

      this.setState(items);
    }
    // this.props.GetMembers();
  }

  render() {
    const { members, staticTexts } = this.props;
    return (
      <div className="team-page-wrapper">
        <PageStart
          title={members?.title}
          description={members?.description}
          image={members?.backgroundImage?.path}
          link={"/contacts"}
          linkTitle={staticTexts?.btn_contacts}
          metaTitle={members?.metaTitle}
          metaDescription={members?.metaDescription}
        />
        <div>
          {Object.entries(this.state).map(([key, value]) => {
            return (
              <div className="team-list-wrapped">
                <Typography.Title color="#fff" level={2}>{key}</Typography.Title>
                <div className="team-list">
                  {value?.length &&
                    value.map((item) => {
                      return (
                        <div className="team-card">
                          <div className="image-wrapper">
                            <div className="image-proportion">
                              <img
                                src={generateImageMediaUrl(item?.mediaMain?.path)}
                                title={
                                  item?.mediaMain?.title ? item?.mediaMain?.title : ""
                                }
                                alt={
                                  item?.mediaMain?.altAttribute
                                    ? item?.mediaMain?.altAttribute
                                    : ""
                                }
                              />
                              {/*<img src={`/team/${index + 1}.jpg`} alt=""/>*/}
                              {item?.lnUrl && (
                                <a
                                  href={item?.lnUrl}
                                  target={"_blank"}
                                  rel="noreferrer"
                                >
                                  {/* <InIcon title={""} className={"linkedin-link"} /> */}
                                </a>
                              )}
                            </div>
                          </div>
                          <div className="item-info">
                            <div className="inner-part">
                              <div className="position">{item?.position}</div>
                              <div className="name">{item?.name}</div>
                              <div className="line" />
                              <Link className="team-card__link" target="_blank" to={`/team/${item.id}`}>
                                Կարդալ ավելին
                              </Link>
                              <div className="social__links">
                                {item?.email && (
                                  <div className="icon__item">
                                    <a
                                      href={`mailto:${item.email}`}
                                      target={"_blank"}
                                      rel="noreferrer"
                                    >
                                      <MailIcon />
                                    </a>
                                  </div>
                                )}

                                {item?.lnUrl && (
                                  <div className="icon__item">
                                    <a
                                      href={item?.lnUrl}
                                      target={"_blank"}
                                      rel="noreferrer"
                                    >
                                      <InIcon />
                                    </a>
                                  </div>
                                )}

                                <div className="icons__all">
                                  {(item?.telegram || item?.whatsApp || item?.phoneNumber) && (
                                    <div className="text">
                                      <a
                                        href={`tel:${item.phoneNumber}`}
                                        rel="noreferrer"
                                      >
                                        <PhoneIcon />
                                      </a>
                                    </div>
                                  )}

                                  {item?.telegram && (
                                    <div className="icons__hidden1">
                                      <a
                                        href={item.telegram}
                                        target={"_blank"}
                                        rel="noreferrer"
                                      >
                                        <TelegramIcon />
                                      </a>
                                    </div>
                                  )}

                                  {item?.whatsApp && (
                                    <div className="icons__hidden2">
                                      <a
                                        href={item.whatsApp}
                                        target={"_blank"}
                                        rel="noreferrer"
                                      >
                                        <WhatsAppIcon />
                                      </a>
                                    </div>
                                  )}

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )
          })}
        </div>
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return getPropsFromState(state, ["members", "staticTexts"]);
};

const mapDispatchToProps = {
  GetMembers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Team);
