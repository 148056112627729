// import packages
import { connect } from "react-redux";

//Import assets
import "../assets/styles/containers/singleTeam.scss";
import { ReactComponent as InIcon } from "../assets/images/icons/ic_in.svg";
import { ReactComponent as MailIcon } from "../assets/images/icons/ic_mail.svg";
import { ReactComponent as PhoneIcon } from "../assets/images/icons/ic_phone.svg";
import { ReactComponent as TelegramIcon } from "../assets/images/icons/ic_telegram.svg";
import { ReactComponent as WhatsAppIcon } from "../assets/images/icons/ic_whatsapp.svg";

//Import utils
import { getPropsFromState } from "../redux/mapStateToProps";
import { GetMembers } from "../redux/actions";
import { generateImageMediaUrl } from "../utils/generateMediaUrl";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";

const SingleMember = ({ GetMembers, members: propsMembers }) => {
  const { id } = useParams();
  const [member, setMember] = useState(null);

  useEffect(() => {
    if (id) {
      GetMembers();
      const { members } = propsMembers;
      const singleMemberInfo = members.find((el) => el.id === id);
      if (singleMemberInfo) {
        setMember(singleMemberInfo);
      }
    }
  }, [id]);

  if (!member) {
    return null;
  }

  return (
    <div className="team-page-wrapper">
      <div className="singleTeam-page">
        <div className="singleTeam-left">
          <div className="icons">
            {member?.lnUrl && (
              <a href={member?.lnUrl} target={"_blank"} rel="noreferrer">
                <InIcon />
              </a>
            )}
            {member?.email && (
              <a href={`mailto:${member.email}`} target={"_blank"} rel="noreferrer">
                <MailIcon />
              </a>
            )}

            {member?.phoneNumber && (
              <a href={`tel:${member.phoneNumber}`} target={"_blank"} rel="noreferrer">
                <PhoneIcon />
              </a>
            )}

            {member?.telegram && (
              <a href={member?.telegram} target={"_blank"} rel="noreferrer">
                <TelegramIcon />
              </a>
            )}

            {member?.whatsApp && (
              <a href={member?.whatsApp} target={"_blank"} rel="noreferrer">
                <WhatsAppIcon />
              </a>
            )}


          </div>
          <div className="img">
            <img
              src={generateImageMediaUrl(member?.mediaMain?.path)}
              title={member?.mediaMain?.title ? member?.mediaMain?.title : ""}
              alt={
                member?.mediaMain?.altAttribute
                  ? member?.mediaMain?.altAttribute
                  : ""
              }
            />
          </div>
        </div>

        <div className="singleTeam-right">
          <h3>{member.position}</h3>
          <h1>{member.name}</h1>
          <p>{member.description}</p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return getPropsFromState(state, ["members"]);
};

const mapDispatchToProps = {
  GetMembers,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleMember);
