import { GENERAL_CONSTS } from "../constants";
import { StaticTexts } from "../../constants/staticTexts";

export const initialState = {
    languages: [],
    selectedLanguage: null,
    staticTexts: {},
    mainSlider: [],
    menuList: [],
    categories: [],
    blogs: {
        itemsList: [],
        hasMore: false,
        category: undefined,
    },
    singleBlog: null,
    blogPage: {},
    partners: [],
    services: [],
    works: [],
    members: [],
    features: [],
    contacts: {},
    about: {},
    customPages: {},
    countryCode: 'en',
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case GENERAL_CONSTS.GET_LANGUAGES:
            const languages = action.payload.languages
            const countryCode = action.payload.countryCode
            const enLanguage = languages.find(lg => lg.code === 'en');
            const selectedLanguage = state.selectedLanguage && state.selectedLanguage.id
                && languages.find(lg => lg.code === state.selectedLanguage.code);

            const countryLanguage = languages.find(lg => lg.code === countryCode);

            const newSelectedLanguage = selectedLanguage ? selectedLanguage :
                (countryLanguage ? countryLanguage : (enLanguage ? enLanguage : languages[0]));


            const newState = {
                ...state,
                languages,
                selectedLanguage: newSelectedLanguage,
            };
            if (state.staticTexts && state.staticTexts[newSelectedLanguage.code]) {
                newState.staticTexts = state.staticTexts[newSelectedLanguage.code]
            }

            return newState;
        case GENERAL_CONSTS.CHANGE_LANGUAGE:
            return {
                ...state,
                selectedLanguage: action.payload,
            };
        case GENERAL_CONSTS.GET_STATIC_TEXTS:
            return {
                ...state,
                staticTexts: action.payload[state.selectedLanguage?.code || 'en']
            };
        case GENERAL_CONSTS.GET_COUNTRY_CODE:
            return {
                ...state,
                // staticTexts: ;
            };
        case GENERAL_CONSTS.GET_MENU:
            return {
                ...state,
                menuList: action.payload
            };
        case GENERAL_CONSTS.GET_SLIDER:
            return {
                ...state,
                mainSlider: action.payload
            };
        case GENERAL_CONSTS.GET_SERVICES:
            return {
                ...state,
                services: action.payload
            };
        case GENERAL_CONSTS.GET_WORKS:
            return {
                ...state,
                works: action.payload
            };
        case GENERAL_CONSTS.GET_MEMBERS:
            return {
                ...state,
                members: action.payload
            };
        case GENERAL_CONSTS.GET_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            };
        case GENERAL_CONSTS.GET_BLOGS:
            return {
                ...state,
                blogs: {
                    ...state.blogs,
                    [action.payload.category ? action.payload.category : 'all']: {
                        itemsList: action.payload.reset ? action.payload.data : [...state.blogs[action.payload.category ? action.payload.category : 'all'].itemsList, ...action.payload.data],
                        category: action.payload.category,
                        hasMore: action.payload.hasMore
                    }
                }
            };
        case GENERAL_CONSTS.GET_BLOG_PAGE:
            return {
                ...state,
                blogPage: action.payload
            };
        case GENERAL_CONSTS.GET_SINGLE_BLOG:
            return {
                ...state,
                singleBlog: action.payload,
            };
        case GENERAL_CONSTS.GET_PARTNERS:
            return {
                ...state,
                partners: action.payload
            };
        case GENERAL_CONSTS.GET_FEATURES:
            return {
                ...state,
                features: action.payload
            };
        case GENERAL_CONSTS.GET_CONTACTS:
            return {
                ...state,
                contacts: action.payload
            };
        case GENERAL_CONSTS.GET_ABOUT:
            return {
                ...state,
                about: action.payload
            };
        case GENERAL_CONSTS.GET_CUSTOM_PAGES:
            return {
                ...state,
                customPages: action.payload,
            };
        case GENERAL_CONSTS.CLEAR_CUSTOM_PAGE:
            return {
                ...state,
                customPages: { ...initialState.customPages },
            };
        default:
            return state;
    }
}
