import React, {PureComponent} from 'react';

import {Helmet} from "react-helmet";

import {defaultTexts} from "../constants/defaultTexts";
const logo = 'https://app.askconsult.am/admin/files/default-img.jpeg';

class MetaTags extends PureComponent {

    render() {
        const {title, description, image, url} = this.props;
        const _title = title ? (`${title} | ${defaultTexts.title}`) : defaultTexts.title;
        const _description = description || defaultTexts.description;
        let _image = logo;
        if (image) {
            _image = image;
        }
        const _url = url || window.location.href;
        const {hostname, port, protocol, origin} = window.location;
        const BASE_URL = origin;

        return (<Helmet>
                <title>{_title}</title>
                <meta name="description" content={_description}/>
                <meta property="og:image" content={_image}/>

                <meta property="og:title" content={_title}/>
                <meta property="og:site_name" content='askconsult.am'/>
                <meta property="og:description" content={_description}/>

                <meta property="og:url" content={_url}/>
                <meta property="og:image:width" content={600}/>
                <meta property="og:image:height" content={600}/>
                <meta property="og:type" content={'website'}/>

                <link rel="manifest" href={`${BASE_URL}/manifest.json`}/>
                <link rel="shortcut icon" href={`${BASE_URL}/static/favicon.ico`}/>

            </Helmet>
        )
    }
}

export default MetaTags;
//  {newImage &&  <meta property="og:image" content={newImage}/>}
