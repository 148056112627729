// import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//Import assets
import '../assets/styles/containers/clients.scss'

//Import components
import {PageStart} from "../components/uiElements/PageStart";

//Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {ReactComponent as Arrow} from "../assets/images/icons/ic_arrow_right_green.svg";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";
import {openUrl} from "../utils/helperFunctions";

class Clients extends Component {
    constructor(props) {
        super(props);
        this.img = React.createRef();
    }

    render() {
        const {partners, staticTexts} = this.props;
        return <div className="clients-page-wrapper">
            <PageStart title={partners?.title}
                       description={partners?.description}
                       image={partners?.backgroundImage?.path}
                       className={'small-padding'}
                       metaTitle={partners?.metaTitle}
                       metaDescription={partners?.metaDescription}/>
            <div className="clients-list" ref={this.img}>
                {
                    partners?.partners?.length && partners.partners.map(((item, index) => {
                        return <div className={`client-card`} key={item.id}
                                    onClick={(e) => openUrl('', item?.url)}>
                            <div className="card-proportion">
                                <div className="inner-part">
                                    <div className="image-wrapper">
                                        <img src={generateImageMediaUrl(item?.mediaMainFade?.path)}
                                             className="fade-image"
                                             title={item?.mediaMainFade?.title ? item?.mediaMainFade?.title : ''}
                                             alt={item?.mediaMainFade?.altAttribute ? item?.mediaMainFade?.altAttribute : ''}/>
                                        <img src={generateImageMediaUrl(item?.mediaMain?.path)}
                                             className="main-image"
                                             title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                             alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>
                                    </div>
                                    <div className="item-info">
                                        <div className="title">{item?.title}</div>
                                        <div className="description">
                                            <span className='text'>{item?.description}</span>
                                            <a target={'_blank'} href={item?.url}>
                                                <span>{staticTexts?.btn_more}</span>
                                                <Arrow title={''}/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }))
                }
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['partners', 'staticTexts'])
};

export default connect(mapStateToProps)(Clients);