// import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//Import Components;
import SliderBox from "../components/homepage/SliderBox";
import Services from "../components/homepage/Services";
import About from "../components/homepage/About";
import Sphere from "../components/homepage/Sphere";
import Partners from "../components/homepage/Partners";
import Contacts from "../components/homepage/Contacts";
import Description from "../components/homepage/Description";

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";


class Homepage extends Component {
    render() {
        const {mainSlider, services, works, partners, features, staticTexts} = this.props;

        return <div className="homepage-wrapper">
            <SliderBox mainSlider={mainSlider} staticTexts={staticTexts}/>
            <Services services={services?.services} staticTexts={staticTexts}/>
            <About staticTexts={staticTexts}/>
            {/*<Sphere works={works?.works} staticTexts={staticTexts}/>*/}
            <Description features={features} staticTexts={staticTexts}/>
            {/*<Contacts/>*/}
            <Partners partners={partners?.partners} staticTexts={staticTexts}/>
        </div>
    }
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'mainSlider',
        'works',
        'services',
        'partners',
        'features',
        'about',
        'requestLoading',
        'staticTexts',
    ])
};

const mapDispatchToProps = {};


export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
