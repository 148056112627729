// import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//Import assets
import '../assets/styles/containers/services.scss'

//Import components
import {PageStart} from "../components/uiElements/PageStart";

//Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";
import {openUrl} from "../utils/helperFunctions";

class Services extends Component {
    render() {
        const {services} = this.props;
        return <div className="services-page-wrapper">
            <PageStart title={services?.title}
                       description={services?.description}
                       image={services?.backgroundImage?.path}
                       className={'small-padding'}
                       metaTitle={services?.metaTitle}
                       metaDescription={services?.metaDescription}/>
            <div className="services-list">
                {
                    services?.services?.length && services?.services.map(item => {
                        return <div className="service-card"
                                    key={item?.id}
                                    onClick={() => openUrl(item?.pageSlug, item?.url)}
                        style={{
                            cursor: (item?.pageSlug || item?.url) && 'pointer'
                        }}>
                            <div className="item-info">
                                <div className="inner-part">
                                    <div className="title">{item?.title}</div>
                                    <div className="line"/>
                                    <pre className="description">{item?.description}</pre>
                                </div>
                            </div>
                            <div className="image-wrapper">
                                <div className="image-proportion">
                                    <img src={generateImageMediaUrl(item?.mediaMain?.path)}
                                         title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                         alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'services'
    ])
};

export default connect(mapStateToProps)(Services);