// import packages
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

//import assets
import '../assets/styles/components/header.scss'
import { ReactComponent as Logo } from '../assets/images/Ask  logo.svg';

// Import Components

// Import utils
import { getPropsFromState } from "../redux/mapStateToProps";
import { Link, useLocation } from "react-router-dom";
import { ChangeLanguage } from "../redux/actions";
import { Dropdown, Menu } from "antd";
import { generateImageMediaUrl } from "../utils/generateMediaUrl";
import { ReactComponent as LngIcon } from "../assets/images/icons/ic_lng_arrow.svg";

function Header(props) {
    const { staticTexts, selectedLanguage, languages, toggleLanguage, changeLanguage } = props;
    const [closeLanguage, setCloseLanguage] = useState(false)
    useEffect(() => {
        window.addEventListener("scroll", () => setCloseLanguage(true))
    }, [])
    const menuLanguage = (
        <Menu className="languages-dropdown">
            {
                languages?.length && languages?.map((item) => {
                    return selectedLanguage?.code !== item.code &&
                        <Menu.Item key={item.id}
                            onClick={() => {
                                props.ChangeLanguage(item)
                            }}
                            className={'language-dropdown-item'}>
                            <div className="selected-language">
                                <img src={generateImageMediaUrl(item?.iconPath?.path)}
                                    title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                    alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''} />
                                <span>{item?.name}</span>
                            </div>
                        </Menu.Item>
                })
            }
        </Menu>
    );
    return <div className={`header-wrapper`}>
        <Link to={'/'}>
            <div className={'ask-logo'}>
                <Logo title={''} />
            </div>
        </Link>
        <ul className="nav-menu">
            <Link to={'/services'}>
                <li className="nav-item">{staticTexts?.nav_item_services}</li>
            </Link>
            <Link to={`/team`}>
                <div className="nav-item">{staticTexts?.nav_item_team}</div>
            </Link>
            <Link to={'/about'}>
                <li className="nav-item">{staticTexts?.nav_item_about_us}</li>
            </Link>
            <Link to={'/blog'}>
                <li className="nav-item">{staticTexts?.nav_item_blog}</li>
            </Link>
            <Link to={'/clients'}>
                <li className="nav-item">{staticTexts?.nav_item_partners}</li>
            </Link>
            <Link to={'/contacts'}>
                <li className="nav-item">{staticTexts?.nav_item_contact}</li>
            </Link>

            <li className="nav-item">
                <Dropdown overlay={menuLanguage}
                    trigger={['hover']}
                    overlayClassName={`${closeLanguage ? "close" : ""}`}
                    onClick={() => toggleLanguage(setCloseLanguage(false))}
                    placement={'bottomLeft'}>
                    <a className="ant-dropdown-link">
                        <div className="selected-language">
                            <img src={generateImageMediaUrl(selectedLanguage?.iconPath?.path)}
                                title={selectedLanguage?.iconPath?.mediaMain?.title ? selectedLanguage?.iconPath?.mediaMain?.title : ''}
                                alt={selectedLanguage?.iconPath?.mediaMain?.altAttribute ? selectedLanguage?.iconPath?.mediaMain?.altAttribute : ''} />
                            <div>
                                {/*{selectedLanguage && selectedLanguage?.code}*/}
                                <LngIcon title={''} className="icon-language" />
                            </div>
                        </div>
                    </a>
                </Dropdown>
            </li>
            <a className={`header_burger ${!props.mobileMenuIsOpen ? 'open-menu' : 'close-menu'}`}
               onClick={props.toggleMobileMenu}/>
        </ul>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'languages',
        'selectedLanguage',
    ])
};
const mapDispatchToProps = { ChangeLanguage };
export default connect(mapStateToProps, mapDispatchToProps)(Header);
