// import packages
import React, { Component } from "react";
import { connect } from "react-redux";
//Import assets
import "../assets/styles/containers/work.scss";

//Import components
import { PageStart } from "../components/uiElements/PageStart";

//Import utils
import { getPropsFromState } from "../redux/mapStateToProps";
import { generateImageMediaUrl } from "../utils/generateMediaUrl";
import { openUrl } from "../utils/helperFunctions";

class Work extends Component {
  constructor(props) {
    super(props);
    this.img = React.createRef();
  }

  render() {
    const { works } = this.props;
    return (
      <div className="work-page-wrapper">
        <PageStart
          title={works.title}
          description={works?.description}
          image={works?.backgroundImage?.path}
          className={"small-padding"}
          metaTitle={works?.metaTitle}
          metaDescription={works?.metaDescription}
        />
        <div className="work-list">
          {works?.works?.length &&
            works.works.map((item) => {
              return (
                <div
                  className="work-card"
                  key={item?.id}
                  onClick={() => openUrl(item?.pageSlug, item?.url)}
                  style={{
                    cursor: (item?.pageSlug || item?.url) && "pointer",
                  }}
                >
                  <div className="card-proportion">
                    <img
                      src={generateImageMediaUrl(item?.mediaMain?.path)}
                      title={
                        item?.mediaMain?.title ? item?.mediaMain?.title : ""
                      }
                      alt={
                        item?.mediaMain?.altAttribute
                          ? item?.mediaMain?.altAttribute
                          : ""
                      }
                    />
                  </div>
                  <div className="work-title">{item?.title}</div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return getPropsFromState(state, ["works"]);
};

export default connect(mapStateToProps)(Work);
