import {
    request,
    _urlStaticTexts,
    _urlSlider,
    _urlPartners,
    _urlContacts,
    _urlLanguage,
    _urlCustomPages,
    _urlSubscribers,
    _urlCallRequest,
    _urlMenu,
    _urlAbout,
    _urlServices,
    _urlWorks,
    _urlMembers,
    _urlFeatures,
    _urlCategories,
    _urlBlogs,
    _urlCountryCode1,
    _urlCountryCode2,
} from "../api";
import { GENERAL_CONSTS } from "../constants";

export const getBrowserLanguage = () => {
    return navigator.language.split('-')[0] || null;
};

const _GetCountryCode1 = () => {
    const requestData = {
        url: _urlCountryCode1,
        method: "GET",
    };
    return request(requestData)
        .then((res) => {
            const data = res && res.data;
            const code = data?.countryCode === 'AM' ? 'HY' : data?.countryCode
            return code?.toLowerCase() || 'HY'
        }).catch(_ => 'en')
};

const _GetCountryCode2 = () => {
    const requestData = {
        url: _urlCountryCode2,
        method: "GET",
    };
    return request(requestData)
        .then((res) => {
            const data = res && res.data;
            const code = data?.country_code === 'AM' ? 'HY' : data?.country_code
            return code?.toLowerCase() || 'HY'
        }).catch(_ => 'en')
};

export const GetLanguages = () => {
    const requestData = {
        url: `${_urlLanguage}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async(res) => {
                const countryCode = await _GetCountryCode1()
               // const countryCode = await _GetCountryCode2()
                const data = res && res.data;
                await dispatch({
                    type: GENERAL_CONSTS.GET_LANGUAGES,
                    payload: { countryCode, languages: data }
                })
            })
    };
};

export const GetStaticTexts = () => {
    const requestData = {
        url: `${_urlStaticTexts}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_STATIC_TEXTS,
                    payload: data
                })
            })
    };
};

export const ChangeLanguage = (data) => {
    return dispatch => {
        dispatch({
            type: GENERAL_CONSTS.CHANGE_LANGUAGE,
            payload: data
        })
    }
};

export const GetSlider = () => {
    const requestData = {
        url: `${_urlSlider}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_SLIDER,
                    payload: data
                });
            })
    };
};

export const GetMenu = () => {
    const requestData = {
        url: `${_urlMenu}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_MENU,
                    payload: data
                });
            })
    };
};

export const GetCategories = () => {
    const requestData = {
        url: `${_urlCategories}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_CATEGORIES,
                    payload: data
                })
            })
    };
};

export const GetBlogPage = () => {
    const requestData = {
        url: `${_urlBlogs}/page`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_BLOG_PAGE,
                    payload: data
                })
            })
    };
};

export const GetBlogs = ({
                             reset = true,
                             offset = 0,
                             category,
                             limit = 20,
                         } = {}) => {
    let url = `${_urlBlogs}?offset=${offset}&limit=${limit}`;
    category && (url += `&category=${category}`);
    const requestData = {
        url,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_BLOGS,
                    payload: {
                        data,
                        category,
                        reset,
                        hasMore: data.length === limit,
                    }
                })
            })
    };
};

export const GetSingleBlog = (slug) => {
    const requestData = {
        url: `${_urlBlogs}/blog/${slug}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
    };
};


export const GetPartners = () => {
    const requestData = {
        url: `${_urlPartners}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_PARTNERS,
                    payload: data
                })
            })
    };
};
export const GetFeatures = () => {
    const requestData = {
        url: `${_urlFeatures}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_FEATURES,
                    payload: data
                })
            })
    };
};

export const GetAbout = () => {
    const requestData = {
        url: `${_urlAbout}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_ABOUT,
                    payload: data
                })
            })
    };
};

export const GetWorks = () => {
    const requestData = {
        url: _urlWorks,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_WORKS,
                    payload: data
                })
            })
    };
};
export const GetMembers = () => {
    const requestData = {
        url: _urlMembers,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_MEMBERS,
                    payload: data
                })
            })
    };
};

export const GetContacts = () => {
    const requestData = {
        url: `${_urlContacts}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_CONTACTS,
                    payload: data
                })
            })
    };
};

export const SendEmail = (data) => {
    const requestData = {
        url: `${_urlContacts}`,
        method: "POST",
        data
    };
    return dispatch => {
        // dispatch({type:UTIL_CONSTS.START_LOADING});
        return request(requestData).finally(() => {
            // dispatch({type:UTIL_CONSTS.END_LOADING})
        })
    }
};
export const sendSubscribe = (data) => {
    const requestData = {
        url: `${_urlSubscribers}`,
        method: "POST",
        data
    };
    return dispatch => {
        // dispatch({type:UTIL_CONSTS.START_LOADING});
        return request(requestData).finally(() => {
            // dispatch({type:UTIL_CONSTS.END_LOADING})
        })
    }
};

export const callRequest = (data) => {
    const requestData = {
        url: `${_urlCallRequest}`,
        method:"POST",
        data
    };
    return dispatch => {
        return request(requestData).finally(() =>{})
    }
}
export const GetCustomPages = (url) => {
    const requestData = {
        url: `${_urlCustomPages}/${url}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_CUSTOM_PAGES,
                    payload: data
                })
            })
    };
};


export const ClearCustomPage = () => {

    return async dispatch => {
        await dispatch({
            type: GENERAL_CONSTS.CLEAR_CUSTOM_PAGE
        })
    };
};

export const GetServices = (url) => {
    const requestData = {
        url: `${_urlServices}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_SERVICES,
                    payload: data
                })
            })
    };
};

