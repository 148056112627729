// import packages
import React, {useState} from "react";
import {connect} from "react-redux";

//Import assets
import '../../assets/styles/homepage/contacts.scss'
import bg from '../../assets/images/contact_bg.png'
import {ReactComponent as TickIcon} from '../../assets/images/icons/ic_tick.svg';
import {LoadingOutlined} from '@ant-design/icons';

//Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {sendSubscribe} from "../../redux/actions";
import isEmail from "validator/es/lib/isEmail";

function Contacts(props) {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [tick, setTick] = useState(false);

    const getInputValues = (e) => {
        const {name, value} = e.target;
        setEmail(value);
        setError(false)
    };

    const sendSubscribe = () => {
        if (isEmail(email) && !error) {

            props.sendSubscribe({email}).then(() => {
                setEmail('');
                setTick(true);
                showTick()
            })
                .catch((error)=> {
                    setError(true);
                })
        }
    }
    const showTick = () => {
        setTimeout(
            () => setTick(false),
            2000
        )
    };
    const {staticTexts} = props;
    return <div className="contacts-wrapper">
        <div className="contacts-proportion">
            <img className={'bg-image'} src={bg} alt=""/>
            <div className="form-content">
                <div className="title">{staticTexts?.homepage_subscribe_title}</div>
                <div className="description">{staticTexts?.homepage_subscribe_de}</div>
                <div className="input-wrapper">
                    <input type="text"
                           value={email}
                           placeholder={staticTexts?.homepage_subscribe_placeholder}
                           className={error ? 'error' : ''}
                           onChange={getInputValues}/>

                    <button className='sent-email'
                            onClick={() => {
                                if (!props.requestLoading && sendSubscribe) {
                                    sendSubscribe()
                                }
                            }}>
                        {staticTexts?.btn_send}
                        {
                            props.requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
                                : <TickIcon title={''}
                                            className={`tick-icon ${tick ? 'show-tick' : 'hide-tick'}`}/>
                        }

                    </button>
                </div>
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['staticTexts'])
};

const mapDispatchToProps = {
    sendSubscribe,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);