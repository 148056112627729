// import packages
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom'

// Import assets
import '../assets/styles/components/footer.scss'
import logo from '../assets/images/Ask logo 2.svg'
import { ReactComponent as CompanyIcon } from '../assets/images/4steps.svg';
import { ReactComponent as FbIcon } from '../assets/images/icons/ic_facebook.svg';
import { ReactComponent as LinkedinIcon } from '../assets/images/icons/ic_linkedin.svg';
import { ReactComponent as CollapseIcon } from "../assets/images/icons/ic_arrow_right_green.svg";
import { ReactComponent as LngIcon } from "../assets/images/icons/ic_lng_arrow.svg";

// Import utils
import { getPropsFromState } from "../redux/mapStateToProps";
import { GetContacts, ChangeLanguage } from "../redux/actions";
// import {Checkbox, Collapse} from 'antd';
import { Collapse, Menu, Dropdown } from 'antd';
import { generateImageMediaUrl } from "../utils/generateMediaUrl";

// const {Panel} = Collapse;

const { Panel } = Collapse;

function Footer(props) {
    const { contacts, staticTexts, } = props;

    return <footer className='footer-wrapper'>
        <div className="desktop-footer-wrapper">
            <div className="top-part">
                <div className="col">
                    <div className="logo-wrapper">
                        <img src={logo} alt="" />
                    </div>
                    <div className="icons-wrapper">
                        <a href={contacts?.facebookUrl} target={'_blank'}><FbIcon className='social-icon facebook_icon' title='' /></a>
                        <a href={contacts?.linkedinUrl} target={'_blank'}><LinkedinIcon className='social-icon linkedin_icon' title='' /></a>
                    </div>
                </div>

                <div className="col">
                    <div className="title">{staticTexts?.footer_security}</div>
                    <Link to={`/privacy-policy`}>{staticTexts?.footer_privacy}</Link>
                    <Link to={`/privacy-policy`}>{staticTexts?.footer_terms_conditions}</Link>
                </div>
                <div className="col">
                    <div className="title">{staticTexts?.footer_company}</div>
                    <Link to={`/`}>{staticTexts?.nav_item_home}</Link>
                    <Link to={`/services`}>{staticTexts?.nav_item_services}</Link>

                </div>
                <div className="col">
                    <Link to={'/about'} className="title">{staticTexts?.nav_item_about_us}</Link>
                    <Link to={`/team`}>{staticTexts?.nav_item_team}</Link>
                    <Link to={`/clients`}>{staticTexts?.footer_partners}</Link>
                    <Link to={`/work`}>{staticTexts?.nav_item_work}</Link>
                </div>
                <div className="col">
                    <Link to={'/contacts'} className="title">{staticTexts?.nav_item_contact}</Link>
                    {contacts?.address && <Link to={`/location`}>{contacts?.address}</Link>}
                    {contacts?.phoneNumber && <a href={`tel:${contacts?.phoneNumber}`}>{contacts?.phoneNumber}</a>}
                    {contacts?.email && <a href={`mailto:${contacts?.email}`}>{contacts?.email}</a>}
                </div>
            </div>

            <div className="bottom-part">
                <div className="copyright">{staticTexts?.footer_copyright_text}</div>
                <div className='developed-by'>
                    {staticTexts?.footer_developed_by_text}
                    <a href={"https://4steps.am/"} target={'_blank'}>
                        <CompanyIcon title='' />
                    </a>
                </div>
            </div>
        </div>
        <div className="mobile-footer-wrapper">
            <div className="top-part">
                <div className="logo-wrapper">
                    <img src={logo} alt="" />
                </div>
                <div className="icons-wrapper">
                    {/*<Dropdown overlay={menuLanguage} trigger={['click']} placement={'topLeft'}>*/}
                    {/*    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>*/}
                    {/*        <div className="selected-language">*/}
                    {/*            <img src={generateImageMediaUrl(selectedLanguage?.iconPath?.path)}*/}
                    {/*                 title={selectedLanguage?.iconPath?.mediaMain?.title ? selectedLanguage?.iconPath?.mediaMain?.title : ''}*/}
                    {/*                 alt={selectedLanguage?.iconPath?.mediaMain?.altAttribute ? selectedLanguage?.iconPath?.mediaMain?.altAttribute : ''}/>*/}
                    {/*            <div>*/}
                    {/*                {selectedLanguage && selectedLanguage?.code}*/}
                    {/*                <LngIcon title={''} className="icon-language"/>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </a>*/}
                    {/*</Dropdown>*/}
                    {/* <a href={contacts?.facebookUrl} target={'_blank'}><FbIcon className='social-icon' title=''/></a> */}
                    <a href={contacts?.facebookUrl} target={'_blank'}><FbIcon className='social-icon facebook_icon' title='' /></a>
                    <a href={contacts?.linkedinUrl} target={'_blank'}><LinkedinIcon className='social-icon linkedin_icon' title='' /></a>
                </div>
            </div>

            <Collapse expandIconPosition={'right'}
                expandIcon={(value) => {
                    return <CollapseIcon title={''} className={value.isActive ? 'rotate' : ''} />
                }}>
                <Panel header={staticTexts?.footer_security} key="1">
                    <Link to={`/`}><p className={'link-item'}>{staticTexts?.footer_privacy}</p></Link>
                    <Link to={`/`}><p className={'link-item'}>{staticTexts?.footer_terms_conditions}</p></Link>
                </Panel>
            </Collapse>

            <Collapse expandIconPosition={'right'}
                expandIcon={(value) => {
                    return <CollapseIcon title={''} className={value.isActive ? 'rotate' : ''} />
                }}>
                <Panel header={staticTexts?.footer_company} key="1">
                    <Link to={`/`}><p className={'link-item'}>{staticTexts?.nav_item_home}</p></Link>
                    <Link to={`/services`}><p className={'link-item'}>{staticTexts?.nav_item_services}</p></Link>
                </Panel>
            </Collapse>

            <Collapse expandIconPosition={'right'}
                expandIcon={(value) => {
                    return <CollapseIcon title={''} className={value.isActive ? 'rotate' : ''} />
                }}>
                <Panel header={staticTexts?.nav_item_about_us} key="1">
                    <Link to={`/team`}><p className={'link-item'}>{staticTexts?.nav_item_team}</p></Link>
                    <Link to={`/clients`}><p className={'link-item'}>{staticTexts?.footer_partners}</p></Link>
                    <Link to={`/work`}><p className={'link-item'}>{staticTexts?.nav_item_work}</p></Link>
                </Panel>
            </Collapse>

            <Collapse expandIconPosition={'right'}
                expandIcon={(value) => {
                    return <CollapseIcon title={''} className={value.isActive ? 'rotate' : ''} />
                }}>
                <Panel header={staticTexts?.nav_item_contact} key="1">
                    {contacts?.address && <Link to={`/location`}>{contacts?.address}</Link>}
                    {contacts?.phoneNumber && <a href={`tel:${contacts?.phoneNumber}`}>{contacts?.phoneNumber}</a>}
                    {contacts?.email && <a href={`mailto:${contacts?.email}`}>{contacts?.email}</a>}
                </Panel>
            </Collapse>

            <div className="bottom-side">
                <div className="copyright">{staticTexts?.footer_copyright_text}</div>
                <div className='developed-by'>
                    {staticTexts?.footer_developed_by_text}
                    <a href={"https://4steps.am/"} target={'_blank'}>
                        <CompanyIcon title='' />
                    </a>
                </div>
            </div>
        </div>
    </footer>

}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'selectedLanguage',
    ])
};

const mapDispatchToProps = { GetContacts };

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
