// import packages
import React from "react";
import {Link} from "react-router-dom";

//Import assets
import '../../assets/styles/homepage/sphere.scss'

import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {openUrl} from "../../utils/helperFunctions";


function Sphere(props) {
    const {works, staticTexts} = props;
    return <div className="sphere-wrapper">
        <div className="sphere-header">
            <span className='title'>{staticTexts?.homepage_work_title}</span>
            <Link to={'work'} className='see-more'>{staticTexts?.btn_see_more}</Link>
        </div>
        <div className="sphere-list">
            {
                works?.length && works.map(item => {
                    return  <div className="sphere-card"
                                 key={item?.id}
                                 onClick={() => openUrl(item?.pageSlug, item?.url)}
                                 style={{
                                     cursor: (item?.pageSlug || item?.url) && 'pointer'
                                 }}>
                        <div className="card-proportion">
                            <img src={generateImageMediaUrl(item?.mediaMain?.path)}
                                 title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                 alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>
                        </div>
                        <div className="sphere-title">{item?.title}</div>
                    </div>
                })
            }
        </div>
    </div>
}

export default Sphere;