// import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Tabs} from 'antd';
import moment from "moment";
import InfiniteScroll from 'react-infinite-scroller';

//Import assets
import '../assets/styles/containers/blog.scss'
import {ReactComponent as ArrowIcon} from '../assets/images/icons/ic_arrow_right_green.svg';

//Import components
import {PageStart} from "../components/uiElements/PageStart";

//Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {GetCategories, GetBlogPage, GetBlogs} from "../redux/actions";
import {Link} from "react-router-dom";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";


const {TabPane} = Tabs;

class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: undefined,
            blogData: {},
        };
        this.img = React.createRef();
        this.changeTab = this.changeTab.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    componentDidMount() {
        this.props.GetCategories().then(()=> {
            this.props.categories.map(item => {
                this.props.GetBlogs({
                    category: item?.id,
                });
            })
        });
        this.props.GetBlogPage();
        this.props.GetBlogs();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedLanguage} = this.props;

        if (selectedLanguage && prevProps.selectedLanguage && selectedLanguage.code !== prevProps.selectedLanguage.code) {
            this.props.GetCategories().then(()=> {
                this.props.categories.map(item => {
                    this.props.GetBlogs({
                        category: item?.id,
                    });
                })
            });
            this.props.GetBlogPage();
            this.props.GetBlogs();
        }
    }

    changeTab(key) {
        if (key !== 'all') {
            this.setState({
                activeTab: key
            })
        }
    }

    loadMoreItems() {
        const {blogs} = this.props;

        const {activeTab} = this.state;

        blogs[activeTab ? activeTab : 'all']?.hasMore && this.props.GetBlogs({
            reset: false,
            offset: blogs[activeTab ? activeTab : 'all']?.itemsList?.length,
            category: activeTab,
        });
    };

    render() {
        const {staticTexts, categories, blogPage, blogs} = this.props;

        return <div className="blog-page-wrapper">
            <PageStart title={blogPage?.title}
                       description={blogPage?.description}
                       image={blogPage?.backgroundImage?.path}
                       className={'small-padding'}
                       metaTitle={blogPage?.metaTitle}
                       metaDescription={blogPage?.metaDescription}/>
            <Tabs defaultActiveKey="1" onChange={this.changeTab}>
                <TabPane tab={staticTexts?.categories_all} key="all">
                    {
                         !!blogs?.all?.itemsList?.length &&
                        <InfiniteScroll
                            hasMore={blogs['all']?.hasMore}
                            loadMore={this.loadMoreItems}
                            className={'blog-list'}
                            pageStart={0}
                            useWindow={true}
                            initialLoad={false}>
                            {blogs['all']?.itemsList.map((item) => {
                                return <Link  className="blog-card"
                                              key={item?.id}
                                              to={{
                                                  pathname: `/blog/${item?.slug}`,
                                                  state: {
                                                      data: item,
                                                  }
                                              }}>
                                    <div className="image-wrapper">
                                        <div className="image-proportion">
                                            <img src={generateImageMediaUrl(item?.mediaMain?.path)}
                                                 alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}
                                                 title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}/>
                                        </div>
                                    </div>
                                    <div className="item-info">
                                        <div className="category">{item?.category?.title}</div>
                                        <div className="title">{item?.title}</div>
                                    </div>
                                    <div className="bottom-part">
                                        <div className="date">{moment(item.updatedAt).format("DD.MM.YYYY")}</div>
                                        <Link to={{
                                            pathname: `/blog/${item?.slug}`,
                                            state: {
                                                data: item,
                                            }
                                        }}>
                                            <div className="details-btn">
                                                <ArrowIcon title={''}/>
                                                <span className="more">{staticTexts?.btn_more}</span>
                                            </div>
                                        </Link>
                                    </div>
                                </Link>

                            })}
                        </InfiniteScroll>
                    }
                </TabPane>
                {
                    !!categories?.length && categories.map(item => {
                        return <TabPane tab={item?.title} key={item?.id}>
                            <InfiniteScroll
                                hasMore={blogs[item?.id]?.hasMore}
                                loadMore={this.loadMoreItems}
                                className={'blog-list'}
                                pageStart={0}
                                useWindow={true}
                                initialLoad={false}>
                                {blogs[item?.id] && !!blogs[item?.id]?.itemsList?.length && blogs[item?.id]?.itemsList.map((item) => {
                                    return <div className="blog-card" key={item?.id}>
                                        <div className="image-wrapper">
                                            <div className="image-proportion">
                                                <img src={generateImageMediaUrl(item?.mediaMain?.path)}
                                                     title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                                     alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>
                                            </div>
                                        </div>
                                        <div className="item-info">
                                            <div className="category">{item?.category?.title}</div>
                                            <div className="title">{item?.title}</div>
                                        </div>
                                        <div className="bottom-part">
                                            <div className="date">{moment(item.updatedAt).format("DD.MM.YYYY")}</div>
                                            <Link to={{
                                                pathname: `/blog/${item?.slug}`,
                                                state: {
                                                    data: item,
                                                }
                                            }}>
                                                <div className="details-btn">
                                                    <ArrowIcon title={''}/>
                                                    <span className="more">{staticTexts?.btn_more}</span>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>

                                })}
                            </InfiniteScroll>
                        </TabPane>
                    })
                }
            </Tabs>
        </div>
    }
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'selectedLanguage',
        'staticTexts',
        'categories',
        'blogPage',
        'blogs',
    ])
};
const mapDispatchToProps = {
    GetCategories,
    GetBlogPage,
    GetBlogs,
};

export default connect(mapStateToProps, mapDispatchToProps)(Blog);