// import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { CountUp } from 'use-count-up';
import VisibilitySensor from "react-visibility-sensor";

//Import assets
import '../assets/styles/containers/about.scss'
import "../assets/styles/containers/work.scss";

//Import components
import { PageStart } from "../components/uiElements/PageStart";
import Partners from "../components/homepage/Partners";

//Import utils
import { getPropsFromState } from "../redux/mapStateToProps";
import { generateImageMediaUrl } from "../utils/generateMediaUrl";
import Slider from "react-slick";
import { SliderNextButton, SliderPrevButton } from "../components/uiElements/buttons";
import { openUrl } from "../utils/helperFunctions";



class About extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         disabledPrev: true,
    //         disabledNext: false,
    //     };
    //     this.img = React.createRef();
    // }


    // componentDidMount() {
    //     let itemCount = this.getShowSlideCount();
    //     this.setState({
    //         disabledNext: this.props.partners?.length - 1 < itemCount,
    //     });
    // }

    constructor(props) {
        super(props);
        this.state = {
            disabledPrev: true,
            disabledNext: false,
            disabledPrev2: true,
            disabledNext2: false,
        };
        this.img = React.createRef();
    }



    componentDidMount() {
        let itemCount = this.getShowSlideCount();
        this.setState({
            disabledNext: this.props.partners?.length - 1 < itemCount,
            disabledNext2: this.props.works?.length - 1 < itemCount
        });
    }



    afterSlideChange = (current, next) => {
        let itemCount = this.getShowSlideCount();
        this.setState({
            disabledPrev: next === 0,
            disabledNext: next + itemCount === this.props.partners?.length,
        });
    };


   

    afterSlideChange2 = (next) => {
        let itemCount = 3;
        this.setState({
            disabledPrev2: next === 0,
            disabledNext2: next + itemCount === this.props.works?.length,
        });
            
    };

    
    // afterSlideChange2 = (current, next) => {
    //     let itemCount = 3;
    //     next += 1;
    //     this.setState({
    //         disabledPrev2: next === 0,
    //         disabledNext2: next + itemCount === this.props.works?.length,
    //     });

    //     console.log(next + itemCount);
    //     console.log(next + itemCount === this.props.works?.length);
        
    // };



    getShowSlideCount = () => {
        let width = window.innerWidth;
        if (width > 1200) return 5;
        else if (width > 900) return 4;
        else if (width > 600) return 3;
        else return 2;
    };

 
    render() {
        const { about, staticTexts, partners, works } = this.props;
        const { disabledPrev, disabledNext, disabledPrev2, disabledNext2 } = this.state;

        let block_1 = about?.blocks?.find(item => item.slug === 'block-first');
        let block_2 = about?.blocks?.find(item => item.slug === 'block-second');

        const settings = {
            dots: false,
            arrows: false,
            infinite: window.innerWidth < 700,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
            centerMode: window.innerWidth < 700,
            centerPadding: '60px 0 0',
            ref: partner => (this.partners = partner),
            beforeChange: (current, next) => this.afterSlideChange(next),
            //rows: 2,
            //slidesPerRow: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 450,
                    settings: {
                        slidesToShow: 1,
                        centerPadding: '80px 0 0',
                    }
                },
            ]
        };

        const settings2 = {
            dots: false,
            arrows: false,
            infinite: window.innerWidth < 700,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: window.innerWidth < 700,
            centerPadding: '60px 0 0',
            ref: work => (this.works = work),
            beforeChange: (current, next) => this.afterSlideChange2(next),
            rows: 1,
            slidesPerRow: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 450,
                    settings: {
                        slidesToShow: 1,
                        centerPadding: '80px 0 0',
                    }
                },
            ]
        };

        return <div className="about-page-wrapper">
            <PageStart title={about?.title}
                description={about?.description}
                link={'team'}
                image={about?.backgroundImage?.path}
                linkTitle={staticTexts?.btn_team}
                alt={about?.backgroundImage?.altAttribute ? about?.backgroundImage?.altAttribute : ''}
                imgTitle={about?.backgroundImage?.title ? about?.backgroundImage?.title : ''}
                metaTitle={about?.metaTitle}
                metaDescription={about?.metaDescription}
            />
            <div className="section">
                <div className='image-wrapper'>
                    <div className="image-proportion">
                        <img src={generateImageMediaUrl(block_1?.mediaMain?.path)}
                            alt={block_1?.mediaMain?.altAttribute ? block_1?.mediaMain?.altAttribute : ''}
                            title={block_1?.mediaMain?.title ? block_1?.mediaMain?.title : ''}
                            className="about-img" />
                    </div>
                </div>
                <div className='text-content'>
                    <div className="section-title">{block_1?.title}</div>
                    <div className="section-description">{block_1?.description}</div>
                </div>
            </div>
            <div className="counter-wrapper">
                <div className="counter-content">
                    <div className="count-item">
                        <span className="countup">
                            <VisibilitySensor>{({ isVisible }) => {
                                return (
                                    <div>
                                        <CountUp isCounting={isVisible} start={0} end={about?.workers} duration={1} delay={3} easing="linear" />
                                    </div>
                                );
                            }}</VisibilitySensor>
                        </span>
                        <span>{staticTexts?.about_page_workers}</span>
                    </div>
                    <div className="count-item">
                        <span className="countup">
                            <VisibilitySensor>{({ isVisible }) => {
                                return (
                                    <div>
                                        <CountUp isCounting={isVisible} start={0} end={about?.partners} duration={1} delay={3} easing="linear" />
                                    </div>
                                );
                            }}</VisibilitySensor>
                        </span>
                        <span>{staticTexts?.about_page_partners}</span>
                    </div>
                    <div className="count-item">
                        <span className="countup">
                            <VisibilitySensor>{({ isVisible }) => {
                                return (
                                    <div>
                                        + <CountUp isCounting={isVisible} start={0} end={about?.bookkeeping} duration={1} delay={3} easing="linear" />
                                    </div>
                                );
                            }}</VisibilitySensor></span>
                        <span>{staticTexts?.about_page_bookkeeping}</span>
                    </div>
                    <div className="count-item">
                        <span className="countup">
                            {/*{about?.businessPlan}*/}
                            <VisibilitySensor>{({ isVisible }) => {
                                return (
                                    <div>
                                        + <CountUp isCounting={isVisible} start={0} end={about?.businessPlan} duration={1} delay={3} easing="linear" />
                                    </div>
                                );
                            }}</VisibilitySensor>
                        </span>
                        <span>{staticTexts?.about_page_businessPlan}</span>
                    </div>
                </div>
            </div>
            <div className="section second">
                <div className='text-content'>
                    <div className="section-title">{block_2?.title}</div>
                    <div className="sun-editor-editable section-description" dangerouslySetInnerHTML={{ __html: block_2?.description }} />
                </div>
                <div className='image-wrapper'>
                    <div className="image-proportion">
                        <img src={generateImageMediaUrl(block_2?.mediaMain?.path)}
                            alt={block_2?.mediaMain?.altAttribute ? block_2?.mediaMain?.altAttribute : ''}
                            title={block_2?.mediaMain?.title ? block_2?.mediaMain?.title : ''}
                            className="about-img" />
                    </div>
                </div>
            </div>

            <div className="work-page-wrapper">
                <PageStart
                    title={works.title}
                    description={works?.description}
                    image={works?.backgroundImage?.path}
                    className={"small-padding"}
                    metaTitle={works?.metaTitle}
                    metaDescription={works?.metaDescription}
                />
                {/* <div className="work-list">
                    {works?.works?.length &&
                        works.works.map((item) => {
                            return (
                                <div
                                    className="work-card"
                                    key={item?.id}
                                    onClick={() => openUrl(item?.pageSlug, item?.url)}
                                    style={{
                                        cursor: (item?.pageSlug || item?.url) && "pointer",
                                    }}
                                >
                                    <div className="card-proportion">
                                        <img
                                            src={generateImageMediaUrl(item?.mediaMain?.path)}
                                            title={
                                                item?.mediaMain?.title ? item?.mediaMain?.title : ""
                                            }
                                            alt={
                                                item?.mediaMain?.altAttribute
                                                    ? item?.mediaMain?.altAttribute
                                                    : ""
                                            }
                                        />
                                    </div>
                                    <div className="work-title">{item?.title}</div>
                                </div>
                            );
                        })}
                </div> */}
            </div>
            {!!works?.works?.length && <div className="partners-wrapper">
                <div className="partners-list-wrapper">
                    {
                        <Slider {...settings2}>
                            {works?.works?.map((item, index) => {
                                return <div className={(index === 0 || index % 2 === 0) ? "top_image" : ""}>
                                    <div className="work-item" key={item.id}>
                                        <div className="work-content">
                                            <img src={generateImageMediaUrl(item?.mediaMain?.path)} className="main-image"
                                                title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                                alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''} />
                                            <div className="work-title3">{item?.title}</div>
                                        </div>
                                       
                                    </div>
                                    
                                </div>
                            })}
                        </Slider>
                    }
     
                    {!!works?.works?.length && <>
                        <SliderPrevButton disabledPrev={disabledPrev2} cbPrev={() => this.works.slickPrev()} />
                        {works?.works?.length > 3 &&
                            <SliderNextButton disabledNext={disabledNext2} cbNext={() => this.works.slickNext()} />}
                    </>
                    }
                   
                </div>
            </div>}

            {!!partners?.partners?.length && <div className="partners-wrapper">
                <div className="partners-title">{staticTexts?.homepage_partners_title}</div>

                <div className="partners-list-wrapper">
                    {
                        <Slider {...settings}>
                            {partners?.partners?.map(item => {
                                return <a target={'_blank'} href={item?.url} key={item?.id}>
                                    <div className="partner-item" key={item.id}>
                                        <div className="partner-content">
                                            <img src={generateImageMediaUrl(item?.mediaMainFade?.path)} className="fade-image"
                                                title={item?.mediaMainFade?.title ? item?.mediaMainFade?.title : ''}
                                                alt={item?.mediaMainFade?.altAttribute ? item?.mediaMainFade?.altAttribute : ''} />
                                            <img src={generateImageMediaUrl(item?.mediaMain?.path)} className="main-image"
                                                title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                                alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''} />
                                        </div>
                                    </div>
                                </a>
                            })}
                        </Slider>
                    }
                    {!!partners?.partners?.length && <>
                        <SliderPrevButton disabledPrev={disabledPrev} cbPrev={() => this.partners.slickPrev()} />
                        {partners?.partners?.length > this.getShowSlideCount() &&
                            <SliderNextButton disabledNext={disabledNext} cbNext={() => this.partners.slickNext()} />}
                    </>}
                </div>

            </div>}


        </div>
    }
}



const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'about',
        'staticTexts',
        'partners',
        'works'
    ])
};

export default connect(mapStateToProps)(About);