// import packages
import React, {Component} from "react";
import {connect} from 'react-redux';
import {history} from "../configs/history";
import isEmail from "validator/es/lib/isEmail";

//Import assets
import '../assets/styles/containers/contacts.scss';
import {ReactComponent as ClockIcon} from '../assets/images/icons/ic_clock.svg';
import {ReactComponent as MailIcon} from '../assets/images/icons/ic_mail.svg';
import {ReactComponent as MapIcon} from '../assets/images/icons/ic_map.svg';
import {ReactComponent as PhoneIcon} from '../assets/images/icons/ic_phone.svg';
import {ReactComponent as TickIcon} from '../assets/images/icons/ic_tick.svg';
import {LoadingOutlined} from '@ant-design/icons';

//Import Components
import {PageStart} from "../components/uiElements/PageStart";
import {InputGroup} from "../components/uiElements/inputGroup";
import {LinkButton} from "../components/uiElements/buttons";

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {SendEmail} from "../redux/actions";
import Locations from "./Locations";

class Contacts extends Component {
    constructor() {
        super();
        this.state = {
            contact: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                message: '',
            },
            errors: {
                email: false,
                firstName: false,
                lastName: false,
                phone: false,
                message: false,
            },
            tick: false,
        };
        this.getInputValues = this.getInputValues.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.showTick = this.showTick.bind(this);
    }

    componentDidMount() {
        const {location} = this.props;
        if (location?.state?.username) {
            this.setState({
                contact: {
                    ...this.state.contact,
                    email: location?.state?.username
                }
            });
            history.replace();
        }
    }

    getInputValues(e) {
        const {name, value} = e.target;
        const phoneRegExp = /^[+\d]\d*$/;
        if (name === 'phone' && value && !phoneRegExp.test(value)) {
            return;
        }
        this.setState({
            contact: {
                ...this.state.contact,
                [name]: value
            }
        })
    }

    validate() {
        const {contact} = this.state;
        const errors = {};
        let result = true;
        Object.keys(contact).forEach(key => {
            if (!contact[key]
                || (key === 'email' && !isEmail(contact?.email))
                || (key === 'phone' && contact.phone?.length < 9)
            ) {
                errors[key] = true;
                result = false;
            }

        })
        this.setState({
            errors
        });
        return result;
    }

    sendMessage() {
        const {firstName, lastName, email, phone, message} = this.state.contact;

        if (this.validate()) {
        let reqData = {
            name: `${firstName} ${lastName}`,
            email,
            phone,
            message
        };
            this.props.SendEmail(reqData).then(() => {
                this.setState({
                    contact: {
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        message: '',
                    },
                    tick: true,
                });
                this.showTick()
            })
        }
    }

    showTick() {
        setTimeout(
            () => this.setState({
                tick: false
            }),
            2000
        )
    }

    render() {
        const {requestLoading, contacts, staticTexts} = this.props;
        const {contact, errors, tick} = this.state;

        return <div className="contacts-page-wrapper">
            <PageStart title={contacts?.title}
                       description={contacts?.description}
                       image={contacts?.backgroundImage?.path}
                       className={'contacts-page-start'}/>
            <div className="contact-card-list">
                <a className="contact-item" href={`mailto:${contacts?.email}`}>
                    <a href={`mailto:${contacts?.email}`} className="icon-wrapper">
                        <MailIcon title={''}/>
                    </a>
                    <div className='item-content'>
                        <div className="title">{staticTexts?.contacts_page_email}</div>
                        <a className="value" href={`mailto:${contacts?.email}`}>{contacts?.email}</ a>
                    </div>
                </a>
                <a className="contact-item" onClick={()=> history.push('/location')}>
                    <div className="icon-wrapper contact-item-map">
                        <MapIcon title={''}/>
                    </div>
                    <div className='item-content'>
                        <div className="title">{staticTexts?.contacts_page_address}</div>
                        <div className="value">{contacts?.address}</div>
                    </div>
                </a>
                <div className="contact-item">
                    <div className="icon-wrapper">
                        <ClockIcon title={''}/>
                    </div>
                    <div className='item-content'>
                        <div className="title">{staticTexts?.contacts_page_work_time}</div>
                        <div className="value">{staticTexts?.contacts_page_work_time_interval}</div>
                    </div>
                </div>
                <a className="contact-item" href={`tel:${contacts?.phoneNumber}`}>
                    <a href={`tel:${contacts?.phoneNumber}`} className="icon-wrapper">
                        <PhoneIcon title={''}/>
                    </a>
                    <div className='item-content'>
                        <div className="title">{staticTexts?.contacts_page_phone}</div>
                        <a className="value" href={`tel:${contacts?.phoneNumber}`}>{contacts?.phoneNumber}</ a>
                    </div>
                </a>
            </div>
            <div className="contact-content">
                <div className="info-content">
                    <Locations/>
                    {/*<div className="contact-title">{contacts?.contentTitle}</div>*/}
                    {/*<p className="description">{contacts?.contentDescription}</p>*/}
                </div>
                <div className="send-message-wrapper">
                    <div className="contact-title">{contacts?.contentTitle}</div>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        name={'firstName'}
                        placeholder={staticTexts?.contacts_page_placeholder_firstName}
                        value={contact.firstName}
                        error={errors.firstName}
                        maxLength={100}
                        onKeyDown={(e) => {
                            e.key === 'Enter' && this.sendMessage()
                        }}
                        onChange={this.getInputValues}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        name={'lastName'}
                        value={contact.lastName}
                        error={errors.lastName}
                        placeholder={staticTexts?.contacts_page_placeholder_lastName}
                        maxLength={100}
                        onKeyDown={(e) => {
                            e.key === 'Enter' && this.sendMessage()
                        }}
                        onChange={this.getInputValues}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        name={'phone'}
                        value={contact.phone}
                        error={errors.phone}
                        placeholder={staticTexts?.contacts_page_placeholder_phoneNumber}
                        maxLength={20}
                        onKeyDown={(e) => {
                            e.key === 'Enter' && this.sendMessage()
                        }}
                        onChange={this.getInputValues}
                    />

                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        name={'email'}
                        value={contact.email}
                        error={errors.email}
                        placeholder={staticTexts?.contacts_page_placeholder_email}
                        maxLength={50}
                        onKeyDown={(e) => {
                            e.key === 'Enter' && this.sendMessage()
                        }}
                        onChange={this.getInputValues}
                    />
                    <div className={'message'}>
                        <InputGroup
                            inputType={'textarea'}
                            type={"text"}
                            name={'message'}
                            value={contact.message}
                            error={errors.message}
                            placeholder={staticTexts?.contacts_page_placeholder_message}
                            onKeyDown={(e) => {
                                e.key === 'Enter' && this.sendMessage()
                            }}
                            onChange={this.getInputValues}
                        />
                    </div>
                    <button className='link-button'
                            onClick={() => {
                                if (!requestLoading && this.sendMessage) {
                                    this.sendMessage();
                                }
                            }}>
                        {staticTexts?.btn_send}
                        {
                            requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
                                : <TickIcon title={''}
                                            className={`tick-icon ${tick ? 'show-tick' : 'hide-tick'}`}/>
                        }

                    </button>
                </div>
            </div>
        </div>

    }
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    SendEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
