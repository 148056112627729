// import packages
import React, { Component } from "react";
import Slider from "react-slick";
//import assets
import "../../assets/styles/homepage/slider.scss";

// Import Components
import { SliderButton } from "../uiElements/buttons";
import { generateImageMediaUrl } from "../../utils/generateMediaUrl";

// Import utils
import { history } from "../../configs/history";

class SliderBox extends Component {
    constructor() {
        super();
        this.state = {
            slideIndex: 0,
            itemHeight: 0,
            sliderCircleEnded: false,
            firstImageLoaded: false,
            loadedImg: false
        };
        this.pagination = React.createRef();
        this.img = React.createRef();
        this.handleImageLoaded = this.handleImageLoaded.bind(this);
    }

    getSliderIndex = (index) => {
        this.setState({
            slideIndex: index,
        });
        this.slider.slickGoTo(index);
    };

    afterChangeSlideIndex = (slideIndex) => {
        this.setState({
            sliderCircleEnded: false,
        })
    };

    beforeChangeSlideIndex = (_, slideIndex) => {
        this.setState({
            slideIndex,
            sliderCircleEnded: true,
        })
    };

    handleImageLoaded() {
        if (!this.state.loadedImg) {
            this.setState({ loadedImg: true });
        }
    }

    openUrl = () => {
        const { mainSlider } = this.props;
        const { slideIndex } = this.state;

        if (!!mainSlider[slideIndex]?.pageSlug) {
            history.push(`/pages/${mainSlider[slideIndex]?.pageSlug}`)
        } else if (!!mainSlider[slideIndex]?.url) {
            window.open(mainSlider[slideIndex].url);
        }
    };

    render() {
        const { mainSlider, staticTexts } = this.props;

        const settingsSlider = {
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 10000,
            pauseOnHover: false,
            lazyLoad: 'progressive',
            afterChange: this.afterChangeSlideIndex,
            beforeChange: this.beforeChangeSlideIndex,
            ref: slider => (this.slider = slider),
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        // window.onorientationchange = function(event) {
        //     console.log("the orientation of the device is now " + event.target.screen.orientation.angle);
        // };
        return <div className="slider-wrapper">
            <div className="image-wrapper" onClick={this.openUrl}>
                <Slider {...settingsSlider}>
                    {mainSlider?.length && mainSlider?.map(item => {
                        let mobileImg = item?.mediaMain?.path?.split('.');
                        mobileImg?.splice(mobileImg?.length - 1, 0, "-mobile");
                        mobileImg?.splice(mobileImg?.length - 1, 0, ".");

                        return <div key={item.id} className={`slider-item`} ref={this.img}>
                            {
                                window.innerWidth > 700
                                    ? <img src={generateImageMediaUrl(item?.mediaMain?.path)}
                                        title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                        alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}
                                        className="about-img" />
                                    : <img src={generateImageMediaUrl((mobileImg.join('')))}
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = generateImageMediaUrl(item?.mediaMain?.path)
                                        }}
                                        title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                        alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}
                                        className="about-img" />
                            }
                            <div className="slider-content">
                                {item?.subTitle && <p>{item?.subTitle}</p>}
                                {item?.title && <div className="title">{item?.title}</div>}
                                {item?.description && <div className="description">{item?.description}</div>}
                                <div className="contact-btn">{staticTexts?.btn_about_us}</div>
                            </div>
                        </div>
                    })}
                </Slider>
                <SliderButton
                    cbPrev={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.slider.slickPrev()
                    }}
                    cbNext={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.slider.slickNext()
                    }}
                />
            </div>
        </div>
    }
}

export default SliderBox;
