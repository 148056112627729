// import packages
import React, {useRef} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {CountUp} from 'use-count-up';
import VisibilitySensor from "react-visibility-sensor";

//Import assets
import '../../assets/styles/homepage/about.scss'
import {ReactComponent as Arrow} from '../../assets/images/icons/ic_arrow_right_green.svg';

import {getPropsFromState} from "../../redux/mapStateToProps";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";


function About(props) {
    const refContainer = useRef(null);
    const {staticTexts, about} = props;


    let block_1 = about?.blocks?.find(item => item.slug === 'block-first');

    return <div className="about-wrapper" ref={refContainer}>
        <div className='image-wrapper'>
            <div className="image-proportion">
                <img src={generateImageMediaUrl(block_1?.mediaMain?.path)}
                     title={block_1?.mediaMain?.title ? block_1?.mediaMain?.title : ''}
                     alt={block_1?.mediaMain?.altAttribute ? block_1?.mediaMain?.altAttribute : ''}
                     className="about-img"/>
            </div>
        </div>
        <div className='about-content'>
            <div className="title">{block_1?.title}</div>

            <p className="description">{block_1?.description}</p>

            <Link to={'/about'}>
                <span>{staticTexts?.homepage_about_details_link}</span>
                <Arrow title={''}/>
            </Link>

            <div className="counter-wrapper">
                <div className="count-item">
                    <span className="countup">
                        <VisibilitySensor>{({isVisible}) => {
                    return (
                        <div>
                            <CountUp isCounting={isVisible} start={0} end={about?.workers} duration={1} delay={3} easing="linear"/>
                        </div>
                    );
                }}</VisibilitySensor>
                    </span>
                    <span>{staticTexts?.about_page_workers}</span>
                </div>
                <div className="count-item">
                    <span className="countup">
                         <VisibilitySensor>{({isVisible}) => {
                             return (
                                 <div>
                                     <CountUp isCounting={isVisible} start={0} end={about?.partners} duration={1} delay={3} easing="linear"/>
                                 </div>
                             );
                         }}</VisibilitySensor>
                        {/*{about?.partners}*/}
                    </span>
                    <span>{staticTexts?.about_page_partners}</span>
                </div>
                <div className="count-item">
                    <span className="countup">
                         <VisibilitySensor>{({isVisible}) => {
                             return (
                                 <div>
                                     <CountUp isCounting={isVisible} start={0} end={about?.bookkeeping} duration={1} delay={3} easing="linear"/>
                                 </div>
                             );
                         }}</VisibilitySensor>
                        {/*{about?.bookkeeping}*/}
                    </span>
                    <span>{staticTexts?.about_page_bookkeeping}</span>
                </div>
            </div>

        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'about',
    ])
};

export default connect(mapStateToProps)(About);