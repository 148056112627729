// Import packages
import React from "react";
import {connect} from 'react-redux';

// Import styles
import '../assets/styles/containers/privacy-policy.scss';

// Import components

//import utils
import {getPropsFromState} from "../redux/mapStateToProps";

function PrivacyPolicy(props) {
    const {contacts} = props;

    return <section className='privacy-policy-wrapper'>
        <div className="sun-editor-editable privacy-policy-text" dangerouslySetInnerHTML={{__html: contacts?.privacyPolicy}}/>
    </section>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'contacts',
]);

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
