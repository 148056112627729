// Import packages
import React from "react";

// Import utils
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";

// Import styles
import "../../assets/styles/components/uiElements/page-start.scss";
// Import components
import {LinkButton} from "./buttons";
import MetaTags from "../MetaTags";

export function PageStart(props) {
    const {
        title, description, className, link, alt, imgTitle, image, linkTitle, metaTitle,metaDescription
    } = props;

    let mobileImage = image?.split('.');
    mobileImage?.splice(mobileImage?.length - 1, 0, "-mobile");
    mobileImage?.splice(mobileImage?.length - 1, 0, ".");

    return  <div className={`page-start ${className}`}>
        {/*<MetaTags title={metaTitle}*/}
        {/*          description={metaDescription}*/}
        {/*          image={generateImageMediaUrl(generateImageMediaUrl(image))}/>*/}
        <div className="image-proportion">
            {
                window.innerWidth > 700
                    ? <img src={generateImageMediaUrl(image)}
                           alt={alt}
                           title={imgTitle}
                           className="about-img"/>
                    : <img src={generateImageMediaUrl((mobileImage?.join('')))}
                           alt={alt}
                           title={imgTitle}
                           onError={(e) => {
                               e.target.onerror = null;
                               e.target.src = generateImageMediaUrl(image)
                           }}
                           className="about-img"/>
            }
            <div className="bg-overlay"/>
            <div className="content">
                <h1 className="start-title">{title}</h1>
                <h2 className="start-description">{description}</h2>

                {(link && linkTitle) && <LinkButton title={linkTitle} link={link}/>}
            </div>
        </div>
    </div>
}