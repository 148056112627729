// Import packages
import React, {memo} from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";

// Import assets
import '../assets/styles/components/right-menu.scss';

// Import components
import {getPropsFromState} from "../redux/mapStateToProps";
import {Dropdown, Menu} from "antd";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";
import {ReactComponent as LngIcon} from "../assets/images/icons/ic_lng_arrow.svg";
import {ChangeLanguage} from "../redux/actions";

// Import utils



const RightMenu = memo((props) => {
    const {className, staticTexts, toggleMobileMenu,languages, menuList,toggleLanguage,selectedLanguage} = props;

    const menuLanguage = (
        <Menu className="languages-dropdown">
            {
                languages?.length && languages?.map((item) => {
                    return selectedLanguage?.code !== item.code &&
                        <Menu.Item key={item.id}
                                   onClick={() => {
                                       props.ChangeLanguage(item)
                                   }}
                                   className={'language-dropdown-item'}>
                            <div className="selected-language">
                                <img src={generateImageMediaUrl(item?.iconPath?.path)}
                                     title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                     alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>
                                <span>{item?.name}</span>
                            </div>
                        </Menu.Item>
                })
            }
        </Menu>
    );
    function closeModel() {
        toggleLanguage()
    }

    return <section className={`right-menu-wrapper ${className}`}>
        <div className={`right-menu-content ${className}`}>
            <nav className='mobile-nav'>
                <Link to={'/'}>
                    <div className="nav-item">{staticTexts?.nav_item_home}</div>
                </Link>
                <Link to={'/services'}>
                    <div className="nav-item">{staticTexts?.nav_item_services}</div>
                </Link>
                <Link to={'/about'}>
                    <div className="nav-item">{staticTexts?.nav_item_about_us}</div>
                </Link>
                <Link to={'/blog'}>
                    <div className="nav-item">{staticTexts?.nav_item_blog}</div>
                </Link>
                <Link to={'/clients'}>
                    <div className="nav-item">{staticTexts?.nav_item_partners}</div>
                </Link>
                <Link to={'/contacts'}>
                    <div className="nav-item">{staticTexts?.nav_item_contact}</div>
                </Link>
                <div className="nav-item">
                    <Dropdown overlay={menuLanguage}
                              trigger={['click']}
                              // overlayClassName={`${closeLanguage ? "close" : ""}`}
                              placement={'bottomLeft'}>
                        <a className="ant-dropdown-link" onClick={toggleLanguage}>
                            <div className="selected-language">
                                <img src={generateImageMediaUrl(selectedLanguage?.iconPath?.path)}
                                     title={selectedLanguage?.iconPath?.mediaMain?.title ? selectedLanguage?.iconPath?.mediaMain?.title : ''}
                                     alt={selectedLanguage?.iconPath?.mediaMain?.altAttribute ? selectedLanguage?.iconPath?.mediaMain?.altAttribute : ''}/>
                                <div>
                                    {/*{selectedLanguage && selectedLanguage?.code}*/}
                                    <LngIcon title={''} className="icon-language"/>
                                </div>
                            </div>
                        </a>
                    </Dropdown>
                </div>
            </nav>
            <nav className='desktop-nav'>
                {/* <Link to={`/work`}>
                    <div className="nav-item">{staticTexts?.nav_item_work}</div>
                </Link> */}
                {
                    !!menuList?.length && menuList.map(item => {
                        return <Link to={`/pages/${item?.pageSlug}`}>
                            <div className="nav-item">{item?.title}</div>
                        </Link>
                    })
                }
            </nav>
        </div>
        <div className={`mobile-menu-overlay`} onClick={toggleMobileMenu}/>
    </section>
})

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'languages',
        'selectedLanguage',
    ])
};
const mapDispatchToProps = {
    ChangeLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(RightMenu);