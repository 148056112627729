// // Import packages
import React, { Component } from "react";
import { Route, Switch, Router } from "react-router-dom";

// // Import utils
import { history } from "../configs/history";

// // Import pages
import Layout from "../containers/Layout";
import Homepage from "../containers/Homepage";
import NotFound from "../containers/NotFound";
import Contacts from "../containers/Contacts";
import About from "../containers/About";
import PrivacyPolicy from "../containers/PrivacyPolicy";
import Team from "../containers/Team";
import Services from "../containers/Services";
import Clients from "../containers/Clients";
import Work from "../containers/Work";
import Blog from "../containers/Blog";
import BlogDetails from "../containers/BlogDetails";
import Locations from "../containers/Locations";
import CustomPage from "../containers/CustomPage";
import SingleMember from "../containers/SingleMember";

export default class Routes extends Component {
  render() {
    return (
      <Router history={history}>
        <Layout>
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route path="/contacts" component={Contacts} />
            <Route path="/about" component={About} />
            <Route exact path="/team" component={Team} />
            <Route path="/team/:id" component={SingleMember} />
            <Route path="/services" component={Services} />
            <Route path="/clients" component={Clients} />
            <Route path="/work" component={Work} />
            <Route exact path="/blog" component={Blog} />
            <Route exact path="/blog/:slug" component={BlogDetails} />
            <Route exact path="/location" component={Locations} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/pages/:slug" component={CustomPage} />
            <Route exact path="/404" component={NotFound} />
            <Route exact path="*" component={NotFound} />
          </Switch>
        </Layout>
      </Router>
    );
  }
}
