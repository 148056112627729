// ----------------------------------4Steps-server---------------------------------------------------
// const ADMIN_URL = "http://173.249.20.192:9700/api/v1";
// const BASE_URL =  "http://173.249.20.192:9800/api/v1";
// const MEDIA_URL =  "http://173.249.20.192:9700/api/v1";
// export const HOST_MEDIA_URL_V1 = "http://173.249.20.192:9700";

// //----------------------------------Official-server---------------------------------------------------
const ADMIN_URL = "https://app.askconsult.am/admin/api/v1";
const BASE_URL = " https://app.askconsult.am/front/api/v1";
const MEDIA_URL = "https://app.askconsult.am/admin";
export const HOST_MEDIA_URL_V1 = "https://app.askconsult.am/admin";

export const _urlLanguage = BASE_URL + "/languages";
export const _urlMedia = MEDIA_URL + "/media";
export const _urlStaticTexts = ADMIN_URL + "/static-texts";
export const _urlSlider = BASE_URL + "/slider";
export const _urlCategories = BASE_URL + "/categories";
export const _urlPartners = BASE_URL + "/partners";
export const _urlBlogs = BASE_URL + "/blogs";
export const _urlCustomPages = BASE_URL + "/custom-pages";
export const _urlContacts = BASE_URL + "/contact";
export const _urlMenu = BASE_URL + "/menu";
export const _urlAbout = BASE_URL + "/about";
export const _urlSubscribers = BASE_URL + "/subscribers";
export const _urlCallRequest = BASE_URL + "/call-request";
export const _urlServices = BASE_URL + "/services";
export const _urlWorks = BASE_URL + "/works";
export const _urlMembers = BASE_URL + "/members";
export const _urlFeatures = BASE_URL + "/features";
export const _urlCountryCode1 = "http://ip-api.com/json";
export const _urlCountryCode2 = "https://geolocation-db.com/json/";
